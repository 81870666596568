import React from 'react'
import {Link} from 'react-router-dom'
import MyButton from './myButton'
import Context from './context'
const queryString = require('query-string');

function StartPage(props) {
   
   
    return (
        <Context.Consumer>

        {
            ctx => {
                return (
                    <div className="flex-c">
                        <MyButton whenClick={ctx.actions.start} label="الدخول"></MyButton>
                    </div>
                )
            }
        }

        </Context.Consumer>
    
    )
}

export default StartPage
