import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Context from './context'
import MyProgress from './progess'
import LoadingOverlay from 'react-loading-overlay';
import ReportPage from './Report'
import ExeForm from './exeForm';
import ToastMSG from './toastMSG'
import StartPage from './StartPage'

const queryString = require('query-string');
const fileJSON = require('../assets/files/constData.json')

const drawerWidth = 300;

const loginMode = fileJSON.loginMode;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'

    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {

        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const renderPage = (props) => {

    if (props.match.path === '/') {
        return (<ReportPage />)

    }
    if (props.match.path === '/start') {

        return (<StartPage />)

    }

}

/* global wialon */


export default function PersistentDrawerLeft(props) {
    let hash = queryString.parse(props.location.search).authHash;
    let BASE_URL = queryString.parse(props.location.search).baseUrl;
    let HOST_URL = queryString.parse(props.location.search).hostUrl;
    // console.log('Hash in mynav is : '+hash)

    // console.log('P 1')
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    return (
        <Context.Consumer>

            {
                ctx => {

                    if (loginMode === 'authHash') {
                        if (!hash) {
                            window.location.href = HOST_URL
                            return
                        }
                        // console.log('going to check hash length')
                        // console.log('is tried? is : '+ctx.value.isTried)
                        if (hash && hash.length === 32 && !ctx.value.isTried) {
                            // console.log('ok, then auth')
                            const data = { hashOrToken: hash, baseUrl: BASE_URL }
                            // ctx.actions.auth(hash)
                            ctx.actions.auth(data)
                        }
                        else {
                            // console.log('invalid checking with hash '+ctx.value.isTried)
                        }
                    }
                    else {
                        // login with token
                        if (!ctx.value.isTried) {
                            console.log('ok, then auth with token')
                            console.log(BASE_URL)
                            
                            const data = { hashOrToken: '', baseUrl: BASE_URL }
                            // ctx.actions.auth('')
                            ctx.actions.auth(data)
                        }
                        else {
                            // console.log('invalid checking with token '+ctx.value.isTried)
                        }
                    }

                    return (

                        <div className={classes.root}>
                            <CssBaseline />
                            <AppBar
                                style={{ fontFamily: 'Cairo' }}
                                theme={theme}
                                position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: open,
                                })}
                            >
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        className={clsx(classes.menuButton, open && classes.hide)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography style={{ fontFamily: 'Cairo' }} variant="h6" noWrap>
                                        {rendertitile(props)}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                style={{ fontFamily: 'Cairo' }}
                                className={classes.drawer}
                                variant="persistent"
                                anchor="left"
                                open={ctx.value.executing ? false : open}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <div className={classes.drawerHeader}>
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </div>
                                <Divider />
                                {
                                    // here will be executing form
                                    ctx.value.isAuthorized ?
                                        <ExeForm style={{ fontFamily: 'Cairo' }} className="divM" />
                                        :
                                        <p>الدخول غير مخول</p>
                                }

                            </Drawer>
                            <main
                                style={{ fontFamily: 'Cairo', width: "100%", height: "100%" }}
                                className={clsx(classes.content, {
                                    [classes.contentShift]: open,
                                })}
                            >
                                <div className={classes.drawerHeader} />

                                {

                                    <LoadingOverlay
                                        styles={{
                                            wrapper: {},
                                            overlay: (base) => ({
                                                ...base,
                                                background: 'rgba(50, 147, 168)',


                                            }),
                                            content: (base) => ({
                                                ...base,



                                            }),
                                            spinner: {}
                                        }}
                                        style={{ fontFamily: 'Cairo', height: "100%" }}
                                        active={ctx.value.executing || ctx.value.logging || ctx.value.exporting}
                                        spinner
                                        text={
                                            ctx.value.overlayText

                                        }
                                    >
                                        {ctx.value.executing || ctx.value.logging || ctx.value.exporting ? <MyProgress> </MyProgress> : renderPage(props)}
                                        {/* {ctx.value.colData.length>0 ? <MyTable></MyTable> : null} */}

                                    </LoadingOverlay>

                                }
                                {
                                    ctx.value.showAlert ?
                                        <ToastMSG open={true} variant={ctx.value.alertVariant} msg={ctx.value.alertMSG} propOpen={ctx.actions.propOpen}> </ToastMSG>
                                        :
                                        null
                                }

                            </main>
                        </div>
                    );

                }
            }
        </Context.Consumer>

    );



}
function rendertitile(props) {
    return <div>{fileJSON.AppName}</div>
}
