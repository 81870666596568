import React from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'

function TableExamplePagination(props) {
    return (
        <Context.Consumer>
            {
                ctx => {
                    return (<Table celled className="subMyTable">
                        {/* <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>#</Table.HeaderCell>
                            <Table.HeaderCell>المركبة</Table.HeaderCell>
                            <Table.HeaderCell>الحالة</Table.HeaderCell>
                            <Table.HeaderCell>وقت الدخول</Table.HeaderCell>
                            <Table.HeaderCell>وقت الخروج</Table.HeaderCell>
                            <Table.HeaderCell> الفترة</Table.HeaderCell>
                            <Table.HeaderCell>المسافة </Table.HeaderCell>
                            <Table.HeaderCell>المحرك </Table.HeaderCell>

                            <Table.HeaderCell> دخول المكبس</Table.HeaderCell>
                            <Table.HeaderCell> خروج من المكبس</Table.HeaderCell>
                            <Table.HeaderCell> المدة </Table.HeaderCell>
                            <Table.HeaderCell> دخول المحطة التحويلة </Table.HeaderCell>
                            <Table.HeaderCell> خروج المحطة التحويلة </Table.HeaderCell>
                            <Table.HeaderCell> المدة </Table.HeaderCell>

                            </Table.Row>
                        </Table.Header> */}

                        <Table.Body>

                            {
                                // props.data.map(function (value, index) {
                                //     return (
                                        <Table.Row>
                                           <Table.Cell >{ props.data}</Table.Cell>
                                           

                                        </Table.Row>
                            //          )
                            //    })

                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>

                            </Table.Row>
                        </Table.Footer>
                    </Table>)
                }
            }
        </Context.Consumer>
    )
}

export default TableExamplePagination